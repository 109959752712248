@tailwind base;
@tailwind components;
@tailwind utilities;

[type="checkbox"]:checked {
  background-image: none;
}

@layer utilities {
  .square {
    aspect-ratio: 1;
  }
}

@font-face {
  font-family: "DMSansRegular";
  src: local("DMSansRegular"),
    url("./fonts/DMSans-Regular.ttf") format("truetype");
}

.font-secondary-regular {
  font-family: "DMSansRegular";
}
