.button {
  border-radius: 0.25rem;
  background-repeat: no-repeat;
}

.gradient {
  background-image: linear-gradient(
    90deg,
    #efea5a -9%,
    #00da9d 55%,
    #10312b 117%
  );
}
.gradient:hover {
  /* box-shadow: 0 0 0 1px black; */
  background-image: none;
  background-color: transparent;
}

.transparent {
  background-color: transparent;
}
.grey {
  background-color: #cecece;
}
.transparent:hover {
  background-image: linear-gradient(
    90deg,
    #efea5a -9%,
    #00da9d 55%,
    #10312b 117%
  );
}

.border {
  background-image: linear-gradient(
    90deg,
    #efea5a -9%,
    #00da9d 55%,
    #10312b 117%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    2px 100%,
    2px 3px,
    3px 2px,
    calc(100% - 3px) 2px,
    calc(100% - 2px) 3px,
    calc(100% - 2px) calc(100% - 3px),
    calc(100% - 3px) calc(100% - 2px),
    3px calc(100% - 2px),
    2px calc(100% - 3px),
    2px 100%,
    100% 100%,
    100% 0%
  );
}
